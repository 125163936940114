import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Steps } from "antd";
import "./HealthCare.scss";
import diagnosisImage from "../../assets/images/healthcare/diagnosis.svg";
import insightsImage from "../../assets/images/healthcare/insights.svg";
import utilizationsImage from "../../assets/images/healthcare/utilizations.svg";
import dataAnalytics from "../../assets/images/healthcare/data-analytics.jpg";

const { Step } = Steps;

class HealthCare extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="page-banners healthcare">
          <div className="container mx-auto">
            <h1>Big Data in Healtcare</h1>
            <p>
              Positive and life-saving outcomes are the two big things that have
              made a big and successful dent through Big Data.
            </p>
          </div>
        </section>
        {/* <!--About aagsoft section starts--> */}
        <div className="about-section">
          <Row justify="center">
            <Col
              xs={{ span: 21 }}
              sm={{ span: 21 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
            >
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h1>Healthcare</h1>
                  <p>
                    Of all the industries that make use of big data analytics,
                    healthcare has the potential to gain the most. Clinical
                    research, digitized medical records, medical imaging,
                    advances in genomics, and a variety of mHealth solutions and
                    wearables have all led to a tech revolution in the
                    healthcare industry.
                  </p>
                  <p>
                    Healthcare analytics help make sense of these massive data
                    sets and drive not only positive but also life-saving
                    outcomes. By relying on data-powered insights, physicians
                    are better equipped to predict the risk of a patient
                    developing a disease and accurately diagnose the condition,
                    which reduces healthcare costs and improves patients’
                    quality of life.
                  </p>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <img src={dataAnalytics} alt="Big Data Analytics" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <!--About aagsoft section ends--> */}
        <section className="ai-work">
          <h1>Big Data Analysis For Smart Healtcare</h1>
          <Row justify="center">
            <Col
              xs={{ span: 21 }}
              sm={{ span: 21 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
            >
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="details">
                    <img src={diagnosisImage} alt="Early diagnosis" />
                    <h3>Early diagnosis</h3>
                    <p>Clinical Risk Score</p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="details">
                    <img src={insightsImage} alt="Detailed Insights" />
                    <h3>Detailed Insights</h3>
                    <p>Evidence Based Protocols</p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="details">
                    <img src={utilizationsImage} alt="Device Utilizations" />
                    <h3>Device Utilizations</h3>
                    <p>False Alarm Reduction</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        {/* <!--About Aagsoft section starts--> */}
        <div className="about-section">
          <Row justify="center">
            <Col
              xs={{ span: 21 }}
              sm={{ span: 21 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>Benefits of digital health</h2>
                  <p>
                    Digital health has the potential to prevent disease and
                    lower healthcare costs, while helping patients monitor and
                    manage chronic conditions. It can also tailor medicine for
                    individual patients.
                  </p>
                  <p>
                    Healthcare providers also can benefit from advances in
                    digital health. Digital tools give healthcare providers an
                    extensive view of patient health by significantly increasing
                    access to health data and giving patients greater control
                    over their health. The result is increased efficiency and
                    improved medical outcomes.
                  </p>
                  <p>
                    The U.S. Food and Drug Administration (FDA) website states:
                    "From mobile medical apps and software that support the
                    clinical decisions doctors make every day to artificial
                    intelligence and machine learning, digital technology has
                    been driving a revolution in health care. Digital health
                    tools have the vast potential to improve our ability to
                    accurately diagnose and treat disease and to enhance the
                    delivery of health care for the individual."
                  </p>
                  <p>
                    Additionally, technologies such as smartphones, social
                    networks and internet applications offer new ways for
                    patients to monitor their health and have increased access
                    to information. "Together, these advancements are leading to
                    a convergence of people, information, technology, and
                    connectivity to improve health care and health outcomes,"
                    according to the FDA.
                  </p>
                  <p>
                    According to the FDA, digital health technologies help
                    providers reduce inefficiencies, improve access, reduce
                    costs, increase quality and make medicine more personalized
                    for patients. At the same time, digital health technologies
                    enable patients and consumers to manage and track health and
                    wellness-related activities more efficiently.
                  </p>
                  <p>
                    While technologies such as virtual reality (VR) tools,
                    wearable medical devices, telehealth and 5G help improve
                    treatment for patients, medical professionals can streamline
                    their workflows using AI-powered systems.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <!--About Aagsoft section ends--> */}

        <div className="bigdata-healthcare-benefits">
          <Row justify="center">
            <Col
              xs={{ span: 21 }}
              sm={{ span: 17 }}
              md={{ span: 15 }}
              lg={{ span: 14 }}
            >
              <div className="details">
                <h3>Big data use case in healthcare</h3>
                <p>
                  Apixio, a leading provider of healthcare analytics services,
                  leverages machine learning to take clinical decision-making to
                  the next level while improving operational efficiencies. By
                  extracting and analyzing health records, the company empowers
                  healthcare providers with granular insights into patient
                  health. In 2018, Apixio’s ML-powered platform analyzed 4.5
                  million health charts, reducing the time and effort to code
                  health charts by 80%.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default HealthCare;
