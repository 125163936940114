import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import AboutUsImage from "../../assets/images/about-aagsoft.png";

class AboutUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners about-us">
          <div className="container mx-auto">
            <h1>About Us</h1>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages light-bg about-us">
          <div className="container mx-auto">
            <Row align="center" gutter={[25, 25]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
              >
                <img src={AboutUsImage} alt="About aagsoft" />
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 20 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
              >
                <h2>Our Mission</h2>
                <p>
                  aagsoft was founded with the mission to combine the power of
                  data with our expertise and services to change the way data
                  assets are managed. Most of today's data is unstructured, in
                  the form of text, voice, images and video. Data combined with
                  right technology & skills, organizations can solve hard
                  problems and change the planet for the better. We offer
                  expertise and services that empowers organizations to
                  effectively integrate their data, decisions, and operations.
                </p>
              </Col>
            </Row>
          </div>
        </section>

        <section className="who-we-are">
          <div className=" container mx-auto">
            <h2>Who We Are</h2>
            <Row align="center" gutter={[25, 25]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="details">
                  <h3>Data Quality and Governance</h3>
                  <p>
                    Who support organizations to reach their quality goals, data
                    governance processes and concurrently enabling them to align
                    with regulatory expectations
                  </p>
                </div>
                <div className="details">
                  <h3>Architects</h3>
                  <p>
                    With experience in large scale systems design and
                    implementation.
                  </p>
                </div>
                <div className="details">
                  <h3> Data Analysts</h3>
                  <p>
                    Ready to help you to get valuable insights from your data.
                  </p>
                </div>
                <div className="details">
                  <h3>Engineers</h3>
                  <p>Proficient in data management technologies.</p>
                </div>
                <div className="details">
                  <h3>Consultants</h3>
                  <p>Who are ready to help you with the right decision.</p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              />
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(AboutUs);
