import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import ContactUs from "../Screens/ContactUs/ContactUs";
import BigDataAnalytics from "../Screens/BigDataAnalytics/BigDataAnalytics";
import HealthCare from "../Screens/HealthCare/HealthCare";
import AboutUs from "../Screens/AboutUs/AboutUs";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import Services from "../Screens/Services/Services";
import DC3 from "../Screens/DC3/DC3";

import Layout from "../Layout/Layout";

class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route exact path={`/contact-us`} component={ContactUs} />

          <Route path="/big-data-analytics" component={BigDataAnalytics} />
          <Route path="/healthcare" component={HealthCare} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/services" component={Services} />
          <Route path="/dc3" component={DC3} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
