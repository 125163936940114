import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "antd";

class HomeSliderList extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.sliderList.map((val) => {
          return (
            <div className={val.classname}>
              {val.videoLink && (
                <div class="carousel-item-video-background">
                  <video className="bgVid" autoPlay muted loop>
                    <source src={val.videoLink} type="video/mp4" />
                  </video>
                </div>
              )}

              <Row justify="center">
                <Col span={24}>
                  <div className="content">
                    <div className="center-content">
                      <div className="slider-caption">
                        <h2>{val.title}</h2>
                        <p>{val.description}</p>

                        <div className="action-buttons">
                          <Link to={val.link} className="btn btn-standard">
                            {val.linkText}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
        {/* quikjets-breadcrumb ends */}
      </React.Fragment>
    );
  }
}

export default withRouter(HomeSliderList);
