import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import AboutUsImage from "../../assets/images/about-aagsoft.png";
import { Link } from "react-router-dom";
import bigDataImage from "../../assets/images/services/big-data.jpg";
import artificialIntelligenceImage from "../../assets/images/services/artificial-intelligence.jpg";
import cloudConsultingImage from "../../assets/images/services/cloud-consulting.jpg";
import dataMigrationImage from "../../assets/images/services/data-migration.jpg";
import healthcareImage from "../../assets/images/services/healthcare.jpg";
import machineLearningImage from "../../assets/images/services/machine-learning.jpg";
import emergingTechnologiesImage from "../../assets/images/services/emerging-technologies.jpg";

class Services extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners services">
          <div className="container mx-auto">
            <h1>Services</h1>
          </div>
        </section>
        {/* page banners */}

        {/* <!--our solutions starts--> */}
        <section className="aagsoft-services">
          <Row justify="center">
            <Col
              xs={{ span: 21 }}
              sm={{ span: 20 }}
              md={{ span: 20 }}
              lg={{ span: 20 }}
            >
              <Row justify="left" gutter={(16, 24)}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <Link to="/big-data-analytics">
                      <img src={bigDataImage} alt="Big Data Analytics" />
                      <h4>Big Data Analytics </h4>
                      <p>
                        Empowering Business to turn data into smart actions
                        using Artificial Intelligence (AI), Machine Learning,
                        Deep Learning, Predictive Analytics, and Natural
                        Language Processing (NLP), to facilitate decision making
                        and business objectives.
                      </p>
                    </Link>
                  </div>
                  {/* <!--List ends--> */}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <Link to="/healthcare">
                      <img src={healthcareImage} alt="Big Data in HealthCare" />
                      <h4>Big Data in HealthCare </h4>
                      <p>
                        Positive and life-saving outcomes are the two big things
                        that have made a big and successful dent through Big
                        Data.
                      </p>
                    </Link>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <img src={cloudConsultingImage} alt="Cloud Consulting" />
                    <h4>Cloud Consulting </h4>
                    <p>
                      Digital transformation for organization starts with cloud
                      and we at aagsoft collaborate with the best-in-class
                      global enterprises to enhance organizations cloud journey.
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <img src={dataMigrationImage} alt="Data Migration" />
                    <h4>Data Migration </h4>
                    <p>
                      Expertise in data migration from system to system,
                      on-premise database to cloud database. Data Migration to
                      Redshift. We have knowledge of both Successfactor and
                      Workday systems with rich experience in migration of HR
                      legacy systems to these modern systems
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <img
                      src={artificialIntelligenceImage}
                      alt="Artificial Intelligence"
                    />
                    <h4>Artificial Intelligence</h4>
                    <p>
                      We build all types of AI functionality which enable
                      Oragnizations to transform business data ecosystem with
                      intelligent algorithms to unearth actionable insights and
                      automate decision-making.
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <img src={machineLearningImage} alt="Machine Learning" />
                    <h4>Machine Learning</h4>
                    <p>
                      Machine Learning Consulting Services for Analytics
                      (Predictive, Text, Business) and Data Insights.
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className="details">
                    <img
                      src={emergingTechnologiesImage}
                      alt="Emerging Technologies"
                    />
                    <h4>Emerging Technologies</h4>
                    <p>
                      Emerging technology strategy needs to be a core part of
                      every company’s corporate strategy. We help you evaluate
                      the business impact and commercial viability of the latest
                      technological advances.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(Services);
