import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import AboutUsImage from "../../assets/images/about-aagsoft.png";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners about-us">
          <div className="container mx-auto">
            <h1>Privacy Policy</h1>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages light-bg privacy-policy">
          <div className="container mx-auto">
            <Row align="center">
              <Col
                xs={{ span: 21 }}
                sm={{ span: 21 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <div className="privacy-text-content">
                  <p>
                    <u>Introduction</u>
                  </p>
                  <p>
                    Your privacy is important to us. We believe it is important
                    for you to know how we treat the information we receive from
                    you, on the Internet. This privacy statement explains what
                    personal data we, aagsoft GMBH (“aagsoft”, “aagsoft” or
                    “we”), collect from you, through our interactions with you
                    on this website, and how we use that data.
                  </p>
                  <p>
                    This Privacy Terms apply to the main website www.aagsoft.com
                    as well as&nbsp; in the&nbsp;
                    <a href="http://sites.aagsoft.com/">sites.aagsoft.com</a>
                    &nbsp;sub-domain (sometimes called “microsites”). Please
                    note that these websites may include links to websites of
                    third parties whose privacy practices differ from those of
                    aagsoft. If you provide personal data to any of those
                    websites your data is governed by their privacy statements.
                  </p>
                  <p>
                    <u>Scope and Usage of Data</u>
                  </p>
                  <p>
                    <b>Personal information you give to us</b>
                  </p>
                  <p>
                    aagsoft collects data to operate this website. You provide
                    some of this data directly, such as when you submit Contact
                    us form, download gated assets form and microsite form.
                    Typically, the contact information we collect is used by us
                    to respond to your enquiry, or allow you to access specific
                    account information. We may execute other activities such as
                    marketing campaign, promotional communications for which
                    consent is taken appropriately or another appropriate lawful
                    basis for the processing is identified.
                  </p>
                  <p>
                    In general, you can visit aagsoft on the World Wide Web
                    without telling us who you are. Our web servers or
                    affiliates who provide analytics and performance enhancement
                    services collect IP address, operating system details,
                    browsing details, device details and language settings. This
                    information is aggregated to measure the number of visits,
                    average time spent on the site, pages viewed and similar
                    information. aagsoft uses this information to measure the
                    site usage, improve content and to ensure safety and
                    security as well enhance performance of the website.
                  </p>
                  <p>
                    You can make choices about our collection and use of your
                    data. For example, you may want to access, edit or remove
                    the personal information in your aagsoft website or
                    microsite account, change your password, or close your
                    account. When you are asked to provide personal data, you
                    may decline. The data we collect depends on the context of
                    your interactions with aagsoft, and the choices you make,
                    including your privacy settings. The data we collect can
                    include the following:
                  </p>
                  <ul>
                    <li>
                      Name and contact data: We collect Full Name, Email
                      address, Phone number and other similar contact data
                    </li>
                  </ul>
                  <p>
                    <u>Purpose of Data Uses</u>
                  </p>
                  <p>
                    aagsoft uses the data we collect for 2 main purposes,
                    described in more detail below: to operate our business and
                    this website; and to send communications, including
                    promotional communications.
                  </p>
                  <ul>
                    <li>
                      Security and Safety: We use data to protect the security
                      and safety of our website.
                    </li>
                    <li>
                      Communications: We use data we collect to communicate with
                      you and personalize our communications with you.&nbsp;
                      Additionally, you can sign up for email subscriptions and
                      choose whether you wish to receive promotional
                      communications from aagsoft by email, SMS, post and
                      telephone or other means of communication. For information
                      about managing your contact data, email subscriptions and
                      promotional communications, please submit a request to us
                      using our contact form at{" "}
                      <a href="https://www.aagsoft.com/#/contact-us">
                        https://www.aagsoft.com/#/contact-us
                      </a>
                      .
                    </li>
                  </ul>
                  <p>
                    <u>Sharing of data</u>
                  </p>
                  <p>
                    We share personal data among aagsoft-controlled affiliates
                    and subsidiaries. We also share personal data with vendors
                    or agents working on our behalf for the purposes described
                    in this statement. For example, companies we've hired to
                    assist in protecting and securing our systems and services
                    may need access to personal data to provide those functions.
                  </p>
                  <p>
                    We usually do not share other personal data collected from
                    the websites with other third parties. However, this may
                    happen if:
                  </p>
                  <ol>
                    <li>You request or authorize us to do so;</li>
                    <li>
                      We need to comply with applicable law or respond to valid
                      legal process
                    </li>
                    <li>
                      We need to operate and also maintain the security of this
                      website, including to prevent or stop an attack on our
                      computer systems or networks;
                    </li>
                  </ol>
                  <p>
                    <u>Cookies</u>
                  </p>
                  <p>aagsoft website do not save or store any cookies.</p>

                  <p>
                    <u>How to Control Cookies</u>
                  </p>
                  <p>
                    Most web browsers automatically accept cookies but provide
                    controls that allow you to block or delete them. For
                    example, certain browser allow to block or delete cookies by
                    clicking Settings &gt; Privacy &gt; Cookies. To find
                    information about specific browsers, please visit the
                    browser developer’s website.
                  </p>
                  <p>
                    <u>Your Communications Preferences</u>
                  </p>
                  <p>
                    If you receive promotional email from us and would like to
                    opt out, you can do so by following the directions in those
                    emails. You can also opt out of email subscriptions and
                    choose whether to share your contact information with
                    aagsoft.
                  </p>
                  <p>
                    <u>How to Contact Us</u>
                  </p>
                  <p>
                    If you have a privacy concern, complaint or a question
                    regarding this privacy statement, please direct it to the
                    Chief Privacy Officer of aagsoft at&nbsp;
                    <a href="mailto:info@aagsoft.com">info@aagsoft.com</a>
                    &nbsp;or contact us through this webform:{" "}
                    <a href="https://www.aagsoft.com/#/contact-us">
                      https://www.aagsoft.com/#/contact-us
                    </a>
                    .
                  </p>
                  <p>
                    <u>European Data Protection Rights - GDPR</u>
                  </p>
                  <p>
                    When we process personal data about you, we do so with your
                    consent and/or as necessary to provide the website you use,
                    operate our business, meet our contractual and legal
                    obligations, protect the security of our systems and our
                    customers, or fulfill other legitimate interests of aagsoft
                    as described in this privacy statement.
                  </p>
                  <p>
                    Below, you will find additional privacy information that you
                    may find important. aagsoft adheres to applicable data
                    protection laws in the European Economic Area, which if
                    applicable includes the following rights:
                  </p>
                  <ul>
                    <li>
                      If the processing of personal data is based on your
                      consent, you have a right to withdraw consent at any time
                      for future processing;
                    </li>
                    <li>
                      You have a right to request from us, a “data controller”
                      as defined in the law, access to and rectification of your
                      personal data;
                    </li>
                    <li>
                      You have a right to request from us that your personal
                      data are erased, subject to certain exceptions;
                    </li>
                    <li>
                      You have a right to object to the processing of your
                      personal data; and
                    </li>
                    <li>
                      You have a right to lodge a complaint with a data
                      protection authority.
                    </li>
                  </ul>
                  <p>
                    <u>Security of Personal Data</u>
                  </p>
                  <p>
                    aagsoft is committed to protecting the security of your
                    personal data. We use a variety of security technologies and
                    procedures to help protect your personal data from
                    unauthorized access, use or disclosure.
                  </p>
                  <p>
                    <u>Where We Store and Process Personal Data</u>
                  </p>
                  <p>
                    Personal data collected by aagsoft may be stored and
                    processed in your region, in India, and in any other country
                    where aagsoft or its affiliates, subsidiaries or service
                    providers operate facilities. The storage location(s) are
                    chosen in order to operate efficiently, to improve
                    performance and to create redundancies in order to protect
                    the data in the event of an outage or other problem. We take
                    steps to ensure that the data we collect under this privacy
                    statement is processed according to the provisions of this
                    statement and the requirements of applicable law wherever
                    the data is located.
                  </p>
                  <p>
                    We transfer personal data from the European Economic Area
                    and Switzerland to other countries, some of which have not
                    been determined by the European Commission to have an
                    adequate level of data protection. When we do, we use a
                    variety of legal mechanisms, including contracts, to help
                    ensure your rights and protections travel with your data.
                  </p>
                  <p>
                    <u>Our Retention of Personal Data</u>
                  </p>
                  <p>
                    aagsoft retains personal data for as long as necessary to
                    provide the access to and use of the website, or for other
                    essential purposes such as complying with our legal
                    obligations, resolving disputes and enforcing our
                    agreements. Because these needs can vary for different data
                    types and purposes, actual retention periods can vary
                    significantly.<u></u>
                  </p>
                  <p>
                    <u>Changes to This Privacy Statement</u>
                  </p>
                  <p>
                    We may update this privacy statement from time to time. We
                    encourage you to periodically review this privacy statement
                    to learn how aagsoft is protecting your information.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(PrivacyPolicy);
