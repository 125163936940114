import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Button, Col, Input } from "antd";
import mainlogo from "../assets/images/aagsoft-logo.svg";

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      headerView: false,
      name: "",
    };
  }

  onCancel = () => {
    this.setState({ open: false });
  };
  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        <div className="cta-header">
          <div className="container mx-auto">
            <Row align="center">
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
              />
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
              >
                <div className="book-jets">
                  <span>Contact us:</span>
                  <a href="tel:+41 79 946 03 07">
                    <i className="flaticon-phone-call" />
                    +41 79 946 03 07
                  </a>
                  <span>or</span>
                  <a href="mailto:info@aagsoft.com">
                    <i className="flaticon-email-1" /> info@aagsoft.com
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                >
                  <div className="left-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={mainlogo} alt="Staging Depot" />{" "}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 17, order: 2 }}
                  lg={{ span: 17, order: 2 }}
                >
                  <div className="bottom-header">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/dc3">Products</Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 3, order: 3 }}
                  lg={{ span: 3, order: 3 }}
                >
                  <div className="signin-signup">
                    <Link to="contact-us" className="btn btn-standard">
                      Get Started
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
