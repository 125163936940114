import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
// import Testimonials from "./Testimonials/Testimonials";
import { Row, Col } from "antd";
import AIImage from "../../assets/images/icons/ai.svg";
import IOTImage from "../../assets/images/icons/iot.svg";
import AnalyticsImage from "../../assets/images/icons/analytics.svg";
import CloudImage from "../../assets/images/icons/cloud.svg";

export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}
        <section className="home-section-ai-work">
          <div className="container mx-auto">
            <h1>Combining Data & AI to Work Together With Humans</h1>
            <Row justify="center">
              <Col span={24}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <div className="count">
                        <span>1</span>
                      </div>
                      <h3>Data Management & Product Strategy</h3>
                      <p>
                        Compare trends within categories, and uncover insights
                        around product success and failure Enable business to
                        identify new products and opportunities using the data
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <div className="count">
                        <span>2</span>
                      </div>
                      <h3>Data Engineering & Platform Architecture</h3>
                      <p>
                        Glue internal and external data to uncover insights
                        around healthcare and life sciences, customer service,
                        real world insights and anything.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <div className="count">
                        <span>3</span>
                      </div>
                      <h3>Quality & Governance</h3>
                      <p>
                        Data is tested, benchmarked and verified for high
                        accuracy
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <div className="count">
                        <span>4</span>
                      </div>
                      <h3>Operations & Supporting Processes</h3>
                      <p>
                        Gain insights to increase efficiency and profitability.
                        Experts across locations at scale to optimize and render
                        services
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>

        <section className="technology-index">
          <div className="container mx-auto">
            <h4>TECHNOLOGY INDEX</h4>
            <h2>We Deliver Solution with the Goal of Trusting Relationships</h2>
            <Row justify="center">
              <Col span={24}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 4 }}
                  >
                    <div className="details">
                      <img src={CloudImage} alt="Cloud" />
                      <p>Cloud</p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 4 }}
                  >
                    <div className="details">
                      <img src={AIImage} alt="Cloud" />
                      <p>AI/ML</p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 4 }}
                  >
                    <div className="details">
                      <img src={AnalyticsImage} alt="Analytics" />
                      <p>Analytics</p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 4 }}
                  >
                    <div className="details">
                      <img src={IOTImage} alt="IOT" />
                      <p>IOT</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>

        {/* Testimonials starts */}
        {/* <Testimonials /> */}
        {/* Testimonials ends */}
      </React.Fragment>
    );
  }
}
