import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import ContactForm from "./ContactForm";
import contactImage from "../../assets/images/main-slider/slider-1.jpg";

class ContactUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners contact-us">
          <div className="container mx-auto">
            <h1>Contact aagsoft</h1>
            <p>
              Thank you for your interest in aagsoft services. Please provide
              the following information about your business needs to help us
              serve you better. This information will enable us to route your
              request to the appropriate person. You should receive a response
              within 24 hours.
            </p>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages top-right-wave contact-details">
          <div className="container mx-auto">
            <Row align="center" gutter={[25, 25]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2>Contact Informations</h2>
                <p>
                  Whether you have a question about anything, our team is ready
                  to answer all your questions
                </p>
                <div className="link">
                  <span>Phone:</span>+41 79 946 03 07
                </div>
                <div className="link">
                  <span>Email:</span>info@aagsoft.com
                </div>
                <div className="link">
                  <span>Location:</span>Spitzwaldstrasse 211, 4123 Allschwil
                </div>
                <img src={contactImage} alt="Quikjets contact us" />
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 20 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2>Send Contact us Request</h2>
                <ContactForm />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(ContactUs);
