import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";
import HomeSliderList from "./HomeSliderList";

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="aagsoft-banner">
          <div className="aagsoft-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <HomeSliderList
                  {...this.props}
                  sliderList={[
                    {
                      classname: "item",
                      title:
                        "Data Management & Consulting Services for Business",
                      description:
                        "Empowering Business to turn data into smart actions using Artificial Intelligence (AI), Machine Learning, Deep Learning, Predictive Analytics, and Natural Language Processing (NLP), to facilitate decision making and business objectives.",
                      linkText: "Know More",
                      link: "/big-data-analytics",
                      videoLink: require("../../../assets/videos/video.mp4"),
                    },
                  ]}
                />
                <HomeSliderList
                  {...this.props}
                  sliderList={[
                    {
                      classname: "item",
                      title: "Cloud Consulting Services",
                      description:
                        "Digital transformation for organization starts with cloud and we at aagsoft collaborate with the best-in-class global enterprises to enhance organizations cloud journey. ",
                      linkText: "View Services",
                      link: "/",
                      videoLink: require("../../../assets/videos/background.mp4"),
                    },
                  ]}
                />
                <HomeSliderList
                  {...this.props}
                  sliderList={[
                    {
                      classname: "item",
                      title: "Emerging Technologies",
                      description:
                        "Emerging technology strategy needs to be a core part of every company’s corporate strategy. We help you evaluate the business impact and commercial viability of the latest technological advances. ",
                      linkText: "View Services",
                      link: "/",
                      videoLink: require("../../../assets/videos/technologies.mp4"),
                    },
                  ]}
                />
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
