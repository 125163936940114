import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import AboutUsImage from "../../assets/images/about-aagsoft.png";
import { Link } from "react-router-dom";
import bigDataImage from "../../assets/images/services/big-data.jpg";
import artificialIntelligenceImage from "../../assets/images/services/artificial-intelligence.jpg";
import cloudConsultingImage from "../../assets/images/services/cloud-consulting.jpg";
import dataMigrationImage from "../../assets/images/services/data-migration.jpg";
import healthcareImage from "../../assets/images/services/healthcare.jpg";
import machineLearningImage from "../../assets/images/services/machine-learning.jpg";
import emergingTechnologiesImage from "../../assets/images/services/emerging-technologies.jpg";
import "./DC3.scss";

class DC3 extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners dcproduct">
          <div className="container mx-auto">
            <h1>DC3</h1>
          </div>
        </section>
        {/* page banners */}

        {/* <!--Products starts--> */}
        <div className="dc3-products">
          <div className="product-details">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h1>DC3</h1>
                    <p>
                      Collects data from any data source, cleanse with approval
                      workflow and consolidates the data to workday templates.
                      Built-in preconfigured conversion rules to migrate data
                      from one system to another system or to cloud. Also includes
                      data quality checks and error reporting based on workday
                      configurations. OCR to read PDF Files to update meta data
                      (used for reading contracts and read information
                      automatically to store in DB)
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/dc3.svg")}
                      alt="dc3"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {/* <!-- Products starts--> */}

        <section className="dynex-features">
          <Row justify="center">
            <Col span={20}>
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <div className="heading">
                    <h2>DC3 Advantages</h2>
                  </div>
                </Col>
              </Row>

              <Row justify="center">
                <Col span={24}>
                  <div className="details">
                    <Row
                      justify="center"
                      gutter={{ xs: 10, sm: 16, md: 16, lg: 16 }}
                    >
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Data collection </p>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Data Cleaning </p>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Data Consolidation </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Data Anonymization </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Audit Trail </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Approval workflow </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Authorization </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Workflow based tool </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>File migration utility </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Archive utility </p>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <p>Task Scheduler </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        <section className="mcbitss-module-points">
          <Row justify="center">
            <Col
              xs={{ span: 20 }}
              sm={{ span: 20 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
            >
              <div className="heading">
                <h2>Features</h2>
              </div>

              <div className="module-list">
                <Row
                  justify="center"
                  gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/data-collection.jpg")}
                      alt="Data Collection"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h3>Data Collection</h3>
                    <p>
                      Easy drag and drop data collection from various data
                      sources like flat files, excel, databases, ERP, BI, API
                      and Web services most flexibly
                    </p>
                  </Col>
                </Row>
              </div>
              {/* row ends */}

              <div className="module-list">
                <Row
                  justify="center"
                  gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12, push: 12 }}
                    lg={{ span: 12, push: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/data-cleancing.jpg")}
                      alt="Data Cleansing"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12, pull: 12 }}
                    lg={{ span: 12, pull: 12 }}
                  >
                    <h3>Data Cleansing</h3>
                    <p>
                      Provides powerful non-script and script based automated
                      cleansing and user friendly manual data cleansing through
                      approval flow.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              {/* list ends here */}

              <div className="module-list">
                <Row
                  justify="center"
                  gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/data-consolidation.jpg")}
                      alt="Data Consolidation"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h3>Data Consolidation</h3>
                    <p>
                      Collected and cleansed data can be aggregated, grouped,
                      harmonized and consolidated for further down stream system
                      processing or export.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              {/* row ends */}

              <div className="module-list">
                <Row
                  justify="center"
                  gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12, push: 12 }}
                    lg={{ span: 12, push: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/security.jpg")}
                      alt="Security"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12, pull: 12 }}
                    lg={{ span: 12, pull: 12 }}
                  >
                    <h3>Security</h3>
                    <p>
                      Security ensures compliance and protect data,
                      applications, data manipulation and data approvals.
                      Flexible role based access provides strong control over
                      data management.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              {/* list ends here */}

              <div className="module-list">
                <Row
                  justify="center"
                  gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/export-data.jpg")}
                      alt="Export data from DC3"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h3>Export</h3>
                    <p>
                      Data can be exported to target systems via direct system
                      transfer, flat files, excel files, API, Webservice call
                      and template based excel files.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              {/* row ends */}

              <div className="module-list">
                <Row
                  justify="center"
                  gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12, push: 12 }}
                    lg={{ span: 12, push: 12 }}
                  >
                    <img
                      src={require("../../assets/images/dc3/audit-log.jpg")}
                      alt="Audit logs"
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12, pull: 12 }}
                    lg={{ span: 12, pull: 12 }}
                  >
                    <h3>Audit logs</h3>
                    <p>
                      Provides flexible configuration of logs on various data
                      points and changes for manual data cleansing and
                      maintenance for transparency and control.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* list ends here */}
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(DC3);
