import React from "react";
import { withRouter } from "react-router";
import InputBox from "../../Components/InputBox/InputBox";
import { Button } from "antd";

class ContactForm extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultActiveKey: [],
      fullName: "",
      emailAddress: "",
      phoneNumber: "",
      contactReason: "",
      message: "",
    };
  }
  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  render() {
    return (
      <React.Fragment>
        <form>
          <div className="form-fields">
            <InputBox
              label="Full Name"
              placeholder="Enter your full name"
              id="fullName"
              value={this.state.fullName}
              onChangeText={this.onChangeText}
              type="text"
              optional="false"
            />
          </div>
          <div className="form-fields">
            <InputBox
              label="Email Address"
              placeholder="Enter your Email Address"
              id="emailAddress"
              value={this.state.emailAddress}
              onChangeText={this.onChangeText}
              type="email"
              optional="false"
            />
          </div>
          <div className="form-fields">
            <InputBox
              label="Phone Number"
              placeholder="Enter your phone number"
              id="phoneNumber"
              value={this.state.phoneNumber}
              onChangeText={this.onChangeText}
              type="text"
              optional="false"
            />
          </div>

          <div className="form-fields">
            <InputBox
              label="Contact Reason"
              placeholder="Contact Reason"
              id="contactReason"
              value={this.state.contactReason}
              onChangeText={this.onChangeText}
              optional="false"
              type="text"
            />
          </div>
          <div className="form-fields">
            <InputBox
              label="Message"
              placeholder="Message"
              id="message"
              value={this.state.message}
              onChangeText={this.onChangeText}
              optional="false"
              textArea={true}
            />
          </div>
          <div className="form-fields">
            <Button type="primary">Contact Now</Button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
export default withRouter(ContactForm);
