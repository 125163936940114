import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col, Divider, Space } from "antd";
import "./Footer.scss";
export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container mx-auto">
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="quick-links">
                <h3>Informations</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/dc3">Products</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="quick-links">
                <h3>Help</h3>
                <ul>
                  <li>
                    <Link to="">Disclaimer</Link>
                  </li>

                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="social-media">
                <h3>Follow Us</h3>
                <ul>
                  <li>
                    <a href="https://twitter.com/aagsoft" target="_blank">
                      <i className="flaticon-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="" target="_blank">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="contact-details">
                <h3>Contact Details</h3>
                <ul>
                  <li>
                    <span>
                      <i class="flaticon-phone-call" /> Phone Number
                    </span>
                    <a href="tel:+41 79 946 03 07">+41 79 946 03 07</a>
                  </li>
                  <li>
                    <span>
                      <i class="flaticon-email-1" />
                      Email{" "}
                    </span>
                    <a href="mailto:info@aagsoft.com">info@aagsoft.com</a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Divider style={{ borderColor: "#1d1d28" }} />
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <p className="copyright">
                Copyright © 2020 - {new Date().getFullYear()} aagsoft. All
                Rights Reserved.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
